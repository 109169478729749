import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-online-services',
    templateUrl: './online-services.component.html',
    styleUrls: ['./online-services.component.css']
})
export class OnlineServicesComponent implements OnInit {
    showMoreInfo1 = false;
    showMoreInfo2 = false;
    showMoreInfo3 = false;
    showMoreInfo4 = false;
    showMoreInfo5 = false;
    showMoreInfo6 = false;

    constructor() { }

    ngOnInit(): void {
    }

    toggleMoreInfo1(): void {
        this.showMoreInfo1 = !this.showMoreInfo1;
    }

    toggleMoreInfo2(): void {
        this.showMoreInfo2 = !this.showMoreInfo2;
    }

    toggleMoreInfo3(): void {
        this.showMoreInfo3 = !this.showMoreInfo3;
    }


    toggleMoreInfo4(): void {
        this.showMoreInfo4 = !this.showMoreInfo4;
    }


    toggleMoreInfo5(): void {
        this.showMoreInfo5 = !this.showMoreInfo5;
    }


    toggleMoreInfo6(): void {
        this.showMoreInfo6 = !this.showMoreInfo6;
    }
}
