import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-in-person-services',
    templateUrl: './in-person-services.component.html',
    styleUrls: ['./in-person-services.component.css']
})
export class InPersonServicesComponent implements OnInit {
    showMoreInfo1 = false;
    showMoreInfo2 = false;
    showMoreInfo3 = false;
    showMoreInfo4 = false;

    constructor() { }

    ngOnInit(): void {
    }

    toggleMoreInfo1(): void {
        this.showMoreInfo1 = !this.showMoreInfo1;
    }

    toggleMoreInfo2(): void {
        this.showMoreInfo2 = !this.showMoreInfo2;
    }

    toggleMoreInfo3(): void {
        this.showMoreInfo3 = !this.showMoreInfo3;
    }


    toggleMoreInfo4(): void {
        this.showMoreInfo4 = !this.showMoreInfo4;
    }
}
