<!-- Header Nav -->
<header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center">
        <div class="logo me-auto">
            <h1><a routerLink="/">Rafaelli Antes</a></h1>
        </div>

        <nav id="navbar" class="navbar order-last order-lg-0">
            <ul *ngIf="!showNavbarMobile">
                <li>
                    <a class="nav-link" [ngClass]="{'active': homeActive}" routerLink="/">Home</a>
                </li>
                <li>
                    <a class="nav-link" [ngClass]="{'active': onlineActive}" routerLink="/online">Serviços Online</a>
                </li>
                <li>
                    <a class="nav-link" [ngClass]="{'active': inPersonActive}" routerLink="/presencial">Serviços
                        Presenciais</a>
                </li>
                <li>
                    <a class="nav-link" [ngClass]="{'active': coursesActive}" routerLink="/cursos">Cursos online de
                        Estilo</a>
                </li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle" (click)="onClickNavbarMobile()"></i>
        </nav>

        <div class="header-social-links d-flex align-items-center">
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/rafaelliantes" class="facebook"><i class="bi bi-facebook"></i></a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/rafaelliantes/" class="instagram"><i class="bi bi-instagram"></i></a>
        </div>
    </div>
</header>

<div *ngIf="showNavbarMobile" class="responsive-menu-list">
    <ul>
        <li>
            <a class="nav-link" (click)="onClickNavbarMobile()" [ngClass]="{'active': homeActive}"
                routerLink="/">Home</a>
        </li>
        <li>
            <a class="nav-link" (click)="onClickNavbarMobile()" [ngClass]="{'active': onlineActive}"
                routerLink="/online">Serviços Online</a>
        </li>
        <li>
            <a class="nav-link" (click)="onClickNavbarMobile()" [ngClass]="{'active': inPersonActive}"
                routerLink="/presencial">Serviços Presenciais</a>
        </li>
        <li>
            <a class="nav-link" (click)="onClickNavbarMobile()" [ngClass]="{'active': coursesActive}"
                routerLink="/cursos">Cursos Online de Estilo</a>
        </li>
    </ul>
</div>