import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    homeActive = true;
    onlineActive = true;
    inPersonActive = true;
    coursesActive = true;

    showNavbarMobile = false;

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const routeUrl = event.url.replace('/', '');
                switch (routeUrl) {
                    case '':
                        this.homeActive = true;
                        this.onlineActive = false;
                        this.inPersonActive = false;
                        this.coursesActive = false;
                        break;
                    case 'online':
                        this.homeActive = false;
                        this.onlineActive = true;
                        this.inPersonActive = false;
                        this.coursesActive = false;
                        break;
                    case 'presencial':
                        this.homeActive = false;
                        this.onlineActive = false;
                        this.inPersonActive = true;
                        this.coursesActive = false;
                        break;
                    case 'cursos':
                        this.homeActive = false;
                        this.onlineActive = false;
                        this.inPersonActive = false;
                        this.coursesActive = true;
                        break;
                }
            }
        });
    }

    ngOnInit(): void {
    }

    onClickNavbarMobile() {
        this.showNavbarMobile = !this.showNavbarMobile;
    }
}
