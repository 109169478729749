<section id="courses-main">
    <div class="container">
        <div class="row">
            <section id="courses-1" class="courses courses-container-background">
                <div class="container">
                    <div class="courses-title" data-aos="fade-up">
                        <h2>Cursos Online</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Estilo Estratégico</h3>

                                <p>Meu curso completo de estilo, com todo o passo a passo para que você seja a sua própria consultora de imagem e estilo.</p>

                                <p>Com ele você terá todo o conteúdo e acompanhamento para se tornar uma mulher mais poderosa e confiante e multiplicar o seu guarda roupas.</p>

                                <div class="btn-wrap">
                                    <a target="_blank" rel="noopener noreferrer"
                                        href="https://estiloestrategico.rafaelliantes.com.br/"
                                        class="btn btn-buy background-green">Saiba tudo sobre o Estilo Estratégico!</a>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="courses-2" class="courses courses-container-background">
                <div class="courses-title" data-aos="fade-up">
                    <h2>Cursos Online</h2>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="card" data-aos="zoom-in">
                            <h3>Mini Curso Cabelos & Estilo pessoal</h3>

                            <p>Você tem dificuldade para escolher a melhor cor e o melhor corte para o seu cabelo?</p>

                            <p>Nesse curso eu te ensino como escolher a cor de acordo com o seu contraste pessoal e o que harmoniza melhor com a sua beleza e estilo e escolher um corte que tem a ver com você, o seu estilo e que harmoniza com seus traços faciais.</p>

                            <div class="btn-wrap">
                                <a target="_blank" rel="noopener noreferrer"
                                    href="https://www.hotmart.com/product/cabelo-estilo-pessoal/E63528288F"
                                    class="btn btn-buy background-green">Quero me matricular no Cabelos & Estilo pessoal!</a>
                            </div>                                
                        </div>
                    </div>
                </div>
            </section>
            
            <section id="courses-3" class="courses courses-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <div class="btn-wrap">
                                    <a target="_blank" rel="noopener noreferrer"
                                        href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Acessei%20o%20seu%20site%20e%20quero%20saber%20mais%20sobre%20a%20consultoria%20de%20estilo."
                                        class="btn btn-contact background-red">Tem alguma dúvida sobre a consultoria ou quer um pacote personalizado? Clique aqui.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>