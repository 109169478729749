<section id="in-person-main">
    <div class="container">
        <div class="row">
            <section id="in-person-1" class="in-person in-person-container-background">
                <div class="container">

                    <div class="in-person-title" data-aos="fade-up">
                        <h2>Serviços Presenciais</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Análise de coloração pessoal</h3>

                                <p>Quer saber <b>quais cores mais valorizam sua beleza?</b> Eu vou te mostrar quais são essas cores e como utilizá-las nas roupas, nos acessórios e  nas maquiagens, além de te orientar sobre as melhores cores de cabelo para você.</p>

                                <p>Também te ensino a combinar as cores da sua cartela e como usar cores que não estão nela.</p>

                                <h4>R$297,00</h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo1()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo1">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Questionário inicial (para você fazer em casa)</li>
                                                <li>Análise de coloração pessoal e orientações sobre cores (encontro presencial)</li>
                                                <li>Você recebe sua cartela de cores física para consultar sempre que desejar</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> o encontro tem duração de até 2 horas</p>


                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$297,00</mark> à vista no boleto, pix ou transferência.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1%2C%20quero%20agendar%20a%20minha%20an%C3%A1lise%20de%20colora%C3%A7%C3%A3o%20pessoal!"
                                                class="btn btn-buy background-green">Quero agendar a minha análise de coloração pessoal!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="in-person-2" class="in-person in-person-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Consultoria de Imagem & Estilo completa</h3>

                                <p>Nesse processo faço sua análise de estilo, coloração pessoal e biotipo com ferramentas e técnicas da consultoria de imagem. Você descobre quais são as peças chave para o seu estilo, quais cores valorizam a sua beleza, como valorizar o seu corpo e como combinar as suas roupas através de uma montagem de looks guiada.</p>

                                <p>Você receberá uma estratégia de imagem personalizada e um manual com todas essas informações, para consultar sempre que você quiser.</p>

                                <h4>R$1597,00</h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo2()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo2">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Exercício de investigação (para você fazer em casa)</li>
                                                <li>Facilitação de estilo (encontro online)</li>
                                                <li>Análise de coloração pessoal e análise corporal (encontro presencial)</li>
                                                <li>Apresentação da Estratégia de Imagem e Estilo (encontro online)</li>
                                                <li>Revitalização de armário (encontro presencial)</li>
                                                <li>Montagem de looks (encontro presencial)</li>
                                                <li>Encontro final e entrega do manual de estilo (encontro online)</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> são 3 encontros presenciais e 3 encontros on-line via google meets + 1 mês de acompanhamento após a finalização do processo, para tirar dúvidas sobre a estratégia.</p>

                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$1597,00</mark> à vista no boleto, pix ou transferência ou em até 12x<sup>*</sup> no cartão de crédito.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Quero%20fazer%20a%20consultoria%20de%20imagem%20completa%20presencial."
                                                class="btn btn-buy background-green">Quero fazer a consutoria de imagem completa!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="in-person-3" class="in-person in-person-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Revitalização do guarda-roupa + montagem de looks</h3>

                                <p>Para você que deseja revisar as roupas que já tem no armário, entender o que funciona ou não pra você, o que está faltando e aprender a montar mais looks com as peças que já tem.</p>

                                <h4>R$897,00</h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo3()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo3">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Questionário inicial (para você fazer em casa)</li>
                                                <li>Revitalização do guarda roupas e análise do questionário inicial (encontro presencial)</li>
                                                <li>Montagem de looks (encontro presencial)</li>
                                                <li>Entrega da lista de sugestões de compras (via whatsapp)</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> 2 encontros presenciais + 1 mês de acompanhamento após a finalização do processo.</p>

                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$897,00</mark> à vista no boleto, pix ou transferência ou em até 12x<sup>*</sup> no cartão de crédito.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Quero%20fazer%20a%20revitaliza%C3%A7%C3%A3o%20do%20guarda-roupa%20%2B%20montagem%20de%20looks!"
                                                class="btn btn-buy background-green">Quero fazer a revitalização do guarda-roupa + montagem de looks!!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="in-person-4" class="in-person in-person-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Montagem de looks</h3>

                                <p>Quer multiplicar as roupas que você já tem? Na montagem de looks eu te mostro várias formas de combinar as suas roupas.</p>

                                <h4>R$697,00</h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo4()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo4">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Questionário inicial (para você fazer em casa)</li>
                                                <li>Montagem de looks de acordo com seu desejo de imagem (encontro presencial)</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> o encontro tem duração de 2 horas
                                                aproximadamente.</p>

                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$697,00</mark> à vista no boleto, pix ou transferência ou em até 12x<sup>*</sup> no cartão de crédito.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Quero%20fazer%20a%20montagem%20de%20looks%20presencial!"
                                                class="btn btn-buy background-green">Quero fazer a montagem de looks!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="in-person-5" class="in-person in-person-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <div class="btn-wrap">
                                    <a target="_blank" rel="noopener noreferrer"
                                        href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Acessei%20o%20seu%20site%20e%20quero%20saber%20mais%20sobre%20a%20consultoria%20de%20estilo."
                                        class="btn btn-contact background-red">Tem alguma dúvida sobre a consultoria ou quer um pacote personalizado? Clique aqui.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>