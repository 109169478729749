<!-- Main -->
<section id="main">
    <div class="container">
        <div class="row">
            <div class="col-6 d-flex flex-column justify-content-center"
                data-aos="fade-up">
                <div>
                    <h1 id="main-title">Consultoria de imagem e estilo</h1>
                    <h2>Monte looks de forma estratégica, torne-se uma mulher segura e confiante e multiplique o seu
                        guarda-roupa.</h2>
                </div>
            </div>

            <div class="col-6 main-img" data-aos="fade-left">
                <img src="assets/img/Main.png" class="img-fluid" alt="">
            </div>
        </div>
    </div>
</section>

<section id="description" class="description">
    <div class="container">
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
                <div class="card">
                    <div class="card-header">
                        <ul class="nav nav-tabs card-header-tabs" id="description-list" role="tablist">
                            <li class="nav-item">
                                <a (click)="onNavTabClickTarget()" data-bs-toggle="tab" class="nav-link active" href="#target"
                                    role="tab" aria-controls="target" aria-selected="true">
                                    <h4>Para quem é</h4>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a (click)="onNavTabClickDescription()" data-bs-toggle="tab" class="nav-link"
                                    href="#description" role="tab" aria-controls="description" aria-selected="false">
                                    <h4>O que é</h4>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content mt-3">
                            <div class="tab-pane" [ngClass]="{'active': descriptionTabActive}" id="description"
                                data-aos="fade-up" role="tabpanel" aria-labelledby="description-tab">
                                <p class="definition-paragraph">A consultoria de imagem e estilo é um serviço exclusivo
                                    que tem como objetivo a
                                    criação de uma estratégia de imagem personalizada e adequada aos seus objetivos e
                                    estilo de vida, sempre
                                    respeitando a sua personalidade.</p>

                                <p class="definition-paragraph">O trabalho é desenvolvido para que a sua imagem seja
                                    fortalecida de forma coerente,
                                    trazendo mais confiança e segurança ao se vestir.</p>

                                <p class="definition-paragraph">Através de análises de estilo pessoal, tipo físico e
                                    coloração é possível construir
                                    uma imagem poderosa e um guarda roupas com peças que te valorizam, conversam entre
                                    si e facilitam o seu dia a
                                    dia.</p>
                            </div>

                            <div class="tab-pane" [ngClass]="{'active': !descriptionTabActive}" id="target"
                                role="tabpanel" data-aos="fade-up" aria-labelledby="target-tab">

                                <p>Para você que não se sente valorizada como profissional, que sabe que tem um
                                    grande conhecimento e
                                    capacidade, mas ainda assim tem dificuldade de ser levada a sério, de ser
                                    cogitada por uma promoção ou mesmo
                                    de cobrar mais pelos seus serviços;</p>
                                <p>Para você que é mãe, que passou por grandes mudanças recentemente e não se
                                    reconhece mais no seu estilo ou
                                    mesmo no seu corpo;</p>
                                <p>Para você que tem dificuldades para vestir o seu corpo e acha que nada veste
                                    bem;</p>
                                <p>Para você que está com a autoestima baixa, que às vezes se sente sem graça ou
                                    sem estilo e quer se sentir
                                    mais atraente e bonita;</p>
                                <p>Para você que se sente insegura para se vestir para diferentes ocasiões;</p>
                                <p>Pra você que está incomodada por usar sempre as mesmas roupas e tem dificuldade
                                    para fazer novas combinações
                                    e sair do básico.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>
</section>

<!-- Services -->
<section id="services" class="services">
    <div class="container">
        <div class="section-title" data-aos="fade-up">
            <h2>Serviços</h2>
        </div>

        <div class="row">
            <div routerLink="/online" class="pointer col-lg-4 align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
                <div class="service-card service-card-red">
                    <div class="icon"><i class="bx bx-video"></i></div>
                    <h4 class="title">Serviços Online</h4>
                    <p class="description">Consultoria com acompanhamento online</p>
                </div>
            </div>

            <div class="pointer col-lg-4 align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in"
                data-aos-delay="100">
                <div class="service-card service-card-blue">
                    <div class="icon"><i class="bx bx-group"></i></div>
                    <h4 class="title">Serviços Presenciais</h4>
                    <p class="description">Consultoria com encontro presencial (Brasília)</p>
                </div>
            </div>

            <div class="pointer col-lg-4 align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in"
                data-aos-delay="200">
                <div class="service-card service-card-green">
                    <div class="icon"><i class="bx bx-certification"></i></div>
                    <h4 class="title">Cursos Online</h4>
                    <p class="description">Cursos para transformar o seu estilo</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- About -->
<section id="about" class="about">
    <div class="container">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-5" data-aos="zoom-in">
                <img src="assets/img/about.webp" class="img-fluid about-img" alt="">
            </div>

            <div class="col-sm-5 d-flex flex-column justify-contents-center" data-aos="fade-left">
                <div class="content pt-4 pt-lg-0">
                    <h3>Quem faz</h3>

                    <p class="fst-italic">
                        Prazer, meu nome é <i>Rafaelli Antes</i>, sua consultora de imagem e estilo.
                    </p>

                    <ul>
                        <li>
                            <i class='bx bx-star bx-tada bx-rotate-180' ></i>
                            <!-- <i class="bi bi-star-circle"></i>  -->
                            O meu trabalho não é sobre montar looks bonitos, é sobre
                            ajudar você, mulher, a explorar o máximo do seu
                            potencial, colocando para fora o melhor que você tem para mostrar.</li>
                        <li>
                            <i class='bx bx-star bx-tada bx-rotate-180' ></i>
                            <!-- <i class="bi bi-star-circle"></i>  -->
                            É sobre resgatar a sua autoestima e potencializar os seus
                            resultados pessoais e profissionais.</li>
                    </ul>

                    <p>
                        <b>A sua imagem comunica</b> e eu vou te ensinar a usá-la a seu favor.
                    </p>
                </div>
            </div>
            <div class="col-sm-1"></div>
        </div>
    </div>
</section>