import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    descriptionTabActive = false;

    constructor() { }

    ngOnInit(): void {

    }

    onNavTabClickDescription(): void {
        this.descriptionTabActive = true;
    }

    onNavTabClickTarget(): void {
        this.descriptionTabActive = false;
    }
}
