<section id="online-main">
    <div class="container">
        <div class="row">
            <section id="online-1" class="online online-container-background">
                <div class="container">

                    <div class="online-title" data-aos="fade-up">
                        <h2>Serviços Online</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Consultoria de Imagem & Estilo + Manual</h3>

                                <p>Nesse processo faço sua análise de estilo e biotipo com ferramentas e
                                    técnicas da consultoria de imagem. Você descobre quais são as peças chave
                                    para o seu estilo, como valorizar o seu corpo e como combinar as suas roupas
                                    através de uma montagem de looks guiada.</p>

                                <p>Você receberá uma estratégia de imagem personalizada e um manual com todas
                                    essas informações, para consultar sempre que você quiser.</p>

                                <p>Se você quer descobrir o seu estilo, vestir melhor o seu corpo, aprender a
                                    combinar as suas roupas usando mais cores, acessórios e modelagens
                                    diferenciadas e revisar o seu guarda-roupa, esse pacote é pra você.</p>

                                <h4><sup>12x </sup>R$140,49 <span>R$1297,00 à vista</span></h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo1()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo1">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Exercício de investigação (para você fazer em casa)</li>
                                                <li>Facilitação de estilo (encontro online)</li>
                                                <li>Análise corporal (encontro online)</li>
                                                <li>Apresentação da Estratégia de Imagem e Estilo (encontro online)</li>
                                                <li>Revitalização de armário (você faz em casa com as minhas orientações
                                                    e
                                                    suporte via whatsapp)</li>
                                                <li>Montagem de looks (encontro online)</li>
                                                <li>Encontro final e entrega do manual de estilo (encontro online)</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> 1 mês de acompanhamento após a
                                                finalização do processo, para tirar dúvidas sobre a estratégia.</p>

                                            <p>São 5 encontros ao vivo via google meets + acompanhamento via whatsapp.
                                            </p>

                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$1297,00</mark> à vista no boleto, pix ou
                                                transferência
                                                ou em até 12x
                                                de <mark class="blue">R$140,49*</mark> no cartão de crédito.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Quero%20agendar%20a%20Consultoria%20de%20Imagem%20%26%20Estilo%20on-line%20%2B%20Manual"
                                                class="btn btn-buy background-green">Quero fazer a consultoria!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="online-2" class="online online-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Descubra o seu estilo + Manual (on-line)</h3>

                                <p>Aqui <b>descobriremos o seu estilo</b> com base na sua personalidade, na sua rotina e
                                    nos seus objetivos.</p>

                                <p>Você entenderá quais peças de roupa, cores, acessórios e quais combinações funcionam
                                    melhor dentro do seu estilo e como usá-las.</p>

                                <h4><sup>12x </sup>R$53,88 <span>R$497,00 à vista</span></h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo2()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo2">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Exercício de investigação (para você fazer em casa)</li>
                                                <li>Facilitação de estilo (encontro online)</li>
                                                <li>Apresentação da Estratégia de Imagem e Estilo (encontro online)</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> 1 mês de acompanhamento via
                                                whatsapp e
                                                2 encontros online: o primeiro é um bate papo sobre o seu desejo de
                                                imagem,
                                                rotina e personalidade e o segundo é a entrega do seu manual de estilo,
                                                com
                                                orientações de peças que funcionam para o seu estilo pessoal.</p>

                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$497,00</mark> à vista no boleto, pix ou
                                                transferência ou
                                                em até 12x
                                                de <mark class="blue">R$53,88*</mark> no cartão de crédito.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Quero%20agendar%20o%20servi%C3%A7o%20Descubra%20o%20seu%20estilo%20(on-line)%20%2B%20Manual."
                                                class="btn btn-buy background-green">Quero descobrir o meu estilo!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="online-3" class="online online-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Revitalização do guarda roupas + montagem de looks (on-line)</h3>

                                <p>Para você que deseja revisar as roupas que tem no armário, entender o que funciona ou
                                    não pra você, o que está faltando e aprender a montar mais looks com as peças que já
                                    tem.</p>

                                <h4><sup>12x </sup>R$67,91 <span>R$697,00 à vista</span></h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo3()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo3">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Questionário inicial (para você fazer em casa)</li>
                                                <li>Orientações sobre o Inventário do guarda roupas e análise do
                                                    questionário inicial (encontro on-line)</li>
                                                <li>Montagem de looks (encontro on-line)</li>
                                                <li>Entrega da lista de sugestões de compras (via whatsapp)</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> 2 meses de acompanhamento via
                                                whatsapp e 2 encontros online</p>

                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$697,00</mark> à vista no boleto, pix ou
                                                transferência ou
                                                em até 12x
                                                de <mark class="blue">R$67,91*</mark> no cartão de crédito.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Quero%20agendar%20a%20revitaliza%C3%A7%C3%A3o%20do%20guarda%20roupas%20%2B%20montagem%20de%20looks%20(on-line)"
                                                class="btn btn-buy background-green">Quero revitalizar o meu
                                                armário!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="online-4" class="online online-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Montagem de looks (on-line)</h3>

                                <p>Quer multiplicar as roupas que você já tem? Na montagem de looks on-line eu te mostro
                                    várias formas fazer isso.</p>

                                <h4><sup>12x </sup>R$53,88 <span>R$497,00 à vista</span></h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo4()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo4">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Questionário inicial (para você fazer em casa)</li>
                                                <li>Montagem de looks de acordo com seu desejo de imagem (encontro
                                                    on-line)</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> o encontro tem duração de 2 horas
                                                aproximadamente.</p>

                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$497,00</mark> à vista no boleto, pix ou
                                                transferência ou
                                                em até 12x
                                                de <mark class="blue">R$53,88*</mark> no cartão de crédito.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Quero%20agendar%20a%20montagem%20de%20looks%20(on-line)"
                                                class="btn btn-buy background-green">Quero agendar uma montagem de
                                                looks!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="online-5" class="online online-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Aprenda a vestir o seu corpo + Manual (on-line)</h3>

                                <p>Para você que tem dificuldade de vestir o seu corpo e acha que nada veste bem. Vamos
                                    bater um papo sobre o que te incomoda no seu corpo, quais pontos você quer disfarçar
                                    e quais deseja valorizar e depois vou te mostrar como montar looks de acordo com o
                                    seu corpo.</p>

                                <h4><sup>12x </sup>R$53,88 <span>R$497,00 à vista</span></h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo5()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo5">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Questionário inicial (para você fazer em casa)</li>
                                                <li>Bate papo e análise de silhueta (encontro on-line)</li>
                                                <li>Entrega do manual e orientações para valorizar o seu corpo (encontro
                                                    on-line).</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> 2 encontros online e 1 mês de
                                                acompanhamento via whatsapp para tirar dúvidas.</p>

                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$497,00</mark> à vista no boleto, pix ou
                                                transferência ou
                                                em até 12x
                                                de <mark class="blue">R$53,88*</mark> no cartão de crédito.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Quero%20agendar%20uma%20consultoria%20para%20aprender%20a%20vestir%20o%20meu%20corpo"
                                                class="btn btn-buy background-green">Quero aprender a vestir o meu
                                                corpo!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="online-6" class="online online-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <h3>Montagem de cápsula personalizada (on-line)</h3>

                                <p>Quer montar uma pequena coleção de peças de roupas que combinam entre si? Na montagem
                                    de cápsula podemos fazer isso.</p>

                                <p>Selecionamos 10 peças de roupa dentro do seu armário que renderão 45 looks. Você pode
                                    usar essa coleção no trabalho, em uma mala de viagem ou aproveitá-la em um guarda
                                    roupas mais compacto.</p>

                                <h4><sup>12x </sup>R$58,16 <span>R$597,00 à vista</span></h4>

                                <div class="btn-wrap">
                                    <div (click)="toggleMoreInfo6()" class="pointer btn background-blue" type="button">
                                        Saiba mais!
                                    </div>
                                </div>

                                <div *ngIf="showMoreInfo6">
                                    <div class="more-info-container card card-body">
                                        <div class="more-info">
                                            <p><strong>Etapas:</strong></p>

                                            <ul>
                                                <li>Questionário inicial (para você fazer em casa)</li>
                                                <li>Bate papo sobre as suas necessidades e breve análise do seu guarda
                                                    roupas (encontro on-line)</li>
                                                <li>Entrega da sugestão de cápsula e lista de compras, caso seja
                                                    necessário (encontro on-line)</li>
                                            </ul>

                                            <p><strong class="blue">Duração:</strong> 2 encontros on-line e 1 mês de
                                                acompanhamento via whatsapp.</p>

                                            <p><strong class="red">Investimento:</strong> <mark
                                                    class="green">R$597,00</mark> à vista no boleto, pix ou
                                                transferência ou
                                                em até 12x
                                                de <mark class="blue">R$58,16*</mark> no cartão de crédito.</p>

                                            <p>*Você pode parcelar em menos vezes, caso deseje.</p>

                                            <p>*O parcelamento possui taxas.</p>
                                        </div>

                                        <div class="btn-wrap">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Quero%20agendar%20uma%20montagem%20de%20c%C3%A1psula!"
                                                class="btn btn-buy background-green">Quero agendar uma montagem de
                                                cápsula!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="online-7" class="online online-container-background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card" data-aos="zoom-in">
                                <div class="btn-wrap">
                                    <a target="_blank" rel="noopener noreferrer"
                                        href="https://api.whatsapp.com/send?phone=5561982715062&text=Ol%C3%A1!%20Acessei%20o%20seu%20site%20e%20quero%20saber%20mais%20sobre%20a%20consultoria%20de%20estilo."
                                        class="btn btn-contact background-red">Tem alguma dúvida sobre a consultoria ou quer um pacote personalizado? Clique aqui.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>