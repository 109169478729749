<footer id="footer">
    <section id="contact" class="contact contact-background">
        <div class="container">
            <div class="contact-title" data-aos="fade-up">
                <h2>Contato</h2>
            </div>

            <div class="row">
                <div class="d-flex align-items-stretch" data-aos="fade-right">
                    <div class="info">
                        <div class="row">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-4 email pointer">
                                <i class="bi bi-envelope"></i>
                                <h4>Email:</h4>
                                <p class="no-margin">suporterafaelli@gmail.com</p>
                            </div>
    
                            <div class="col-lg-4 phone pointer">
                                <i class='bx bxl-whatsapp'></i>
                                <h4>Whatsapp:</h4>
                                <p class="no-margin">+55 61 99966-8691</p>
                            </div>
                            <div class="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</footer>